// Gatsby supports TypeScript natively!
import React from "react"
import { PageProps, Link, graphql } from "gatsby"

import Bio from "../components/bio"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { rhythm } from "../utils/typography"

type Data = {
  site: {
    siteMetadata: {
      title: string
    }
  }
  allMarkdownRemark: {
    edges: {
      node: {
        excerpt: string
        frontmatter: {
          title: string
          date: string
          description: string
        }
        fields: {
          slug: string
        }
      }
    }[]
  }
}

const BlogIndex = ({ data, location }: PageProps<Data>) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMarkdownRemark.edges

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Блог" />
      <Bio />
      <h4 style={{fontFamily: "Montserrat", fontSize: rhythm(1), fontWeight: 600, textTransform: "capitalize", letterSpacing: 0.6, marginTop: 0, marginBottom: 20}}>Статьи</h4>
      {posts.map(({ node }) => {
        const title = node.frontmatter.title || node.fields.slug
        return (
          <article key={node.fields.slug} style={{marginBottom: 50}}>
            <header style={{marginBottom: 5}}>
              <small style={{fontSize: 14}}>{node.frontmatter.date} • Время прочтения: { node.frontmatter.timeToRead }  </small>
              <h3
                style={{
                  marginBottom: 5,
                  marginTop: 5,
                  fontFamily: 'Merriweather',
                  fontSize: rhythm(1),
                }}
              >
                <Link style={{ boxShadow: `none` }} to={node.fields.slug}>
                  {title}
                </Link>
              </h3>
            </header>
            <section>
              <p style={{fontSize: rhythm(0.5)}}
                dangerouslySetInnerHTML={{
                  __html: node.frontmatter.description || node.excerpt,
                }}
              />
            </section>
          </article>
        )
      })}
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            timeToRead
          }
        }
      }
    }
  }
`
